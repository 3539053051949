<template>
    <v-alert color="primary" dark icon="mdi-check" border="left" prominent>
        {{ $t('partner.job.is_completed') }}
    </v-alert>
</template>


<script>
export default {
    name: 'FinalizedJobBanner',
    props: {},
    components: {},

    data() {
        return {};
    },

    methods: {},

    computed: {},

    watch: {},
};
</script>


<style lang='scss' scoped></style>