<template>
  <v-dialog v-model="showDialog" width="400">
    <v-card>
      <v-card-title>
        {{ $t('partner.job.start_job') }}
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-4">
        {{ $t('partner.job.start_job_subtitle') }}
      </v-card-text>
      <v-card-actions>
        <v-btn outlined @click="showDialog = false">{{ $t('partner.cancel') }}</v-btn>
        <v-spacer />
        <v-btn color="primary" :loading="loading" :elevation="0" @click="startJob()">{{ $t('partner.start')
          }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import http from "@/api/client";

export default {
  name: "PartInfoDialog",
  props: {
    jobId: Number,
    opentrigger: Number,
  },
  components: {},

  data() {
    return {
      showDialog: false,
      loading: false,
    };
  },

  mounted() { },

  methods: {
    startJob() {
      this.loading = true;
      http()
        .patch(`api2/partner/jobs/${this.jobId}/setStatus/Open`)
        .then(() => {
          this.$router.push({ name: 'ViewJob', params: { id: this.jobId } })
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  computed: {},

  watch: {
    opentrigger: function () {
      this.showDialog = true;
    },
  },
};
</script>