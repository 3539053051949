<template>
    <v-card class="rounded-0 pa-4 py-12" :width="isPadBool ? '100vw' : 350"
        :height="isPadBool ? 'calc(100vh - 56px)' : '100vh'" style="position: fixed; overflow-y: auto; z-index: 3;">
        <v-row no-gutters justify="start" align="center" class="px-4 py-2 ml-n4"
            style="position: fixed; top: 0; z-index: 5; background-color: rgba( 255, 255, 255, 0.8 );"
            :style="'width:' + (isPadBool ? '100vw; min-height: 52px;' : '350px')">
            <v-col cols="auto">
                <h3>{{ $t('partner.job.order') }} {{ job?.Order?.Id }}</h3>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
                <OfflinePdfDialog :job="job" :readonly="isFinalized" @Refresh="$emit('Refresh')">
                </OfflinePdfDialog>
            </v-col>
            <template v-if="isPadBool">
                <v-spacer></v-spacer>
                <v-btn icon class="mr-n2" @click="$emit('change', !showMenu)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-row>

        <FirstLineCommentNote :comment="job.FirstLineComment">
        </FirstLineCommentNote>

        <ServiceManagerCommentNote :comment="job.ServiceManagerComment">
        </ServiceManagerCommentNote>

        <AppointmentCard v-for="(item, index) in job.Appointments" :key="'apppointment-' + index" :appointment="item">
        </AppointmentCard>

        <TableOfContents v-if="isBatchJob && !isPadBool" :job="job">
            <!-- hid it for non batchjobs as it was overkill -->
        </TableOfContents>

        <Attachments :jobId="job.Id" :readonly="isFinalized">
        </Attachments>

        <v-row no-gutters justify="center" class="ml-n4"
            style="position: fixed; width: 350px; background-color: rgba( 255, 255, 255, 0.8 );"
            :style="isPadBool ? 'bottom: 56px;' : 'bottom: 0;'">
            <v-btn v-if="!isFinalized" color="primary" class="mx-4 my-3" :loading="statusLoading"
                @click="$emit('setStatus', 'Completed')">
                <v-icon class="mr-2">mdi-check</v-icon>
                {{ $t('partner.job.mark_as_completed') }}
            </v-btn>
            <FinalizedJobBanner v-else class="mx-4 my-3">
            </FinalizedJobBanner>
        </v-row>
    </v-card>
</template>


<script>
import Attachments from "@/components/Cards/Attachments.vue";
import OfflinePdfDialog from "@/components/Dialogs/OfflinePdfDialog.vue";
import AppointmentCard from "@/components/Cards/AppointmentCard.vue";
import TableOfContents from "@/components/TableOfContents.vue";
import FirstLineCommentNote from "@/components/Cards/FirstLineCommentNote.vue";
import ServiceManagerCommentNote from "@/components/Cards/ServiceManagerCommentNote.vue";
import FinalizedJobBanner from "@/components/Cards/FinalizedJobBanner.vue";

export default {
    name: "JobSideMenu",
    model: {
        prop: "showMenu",
        event: "change",
    },
    props: {
        showMenu: Boolean,
        job: Object,
        statusLoading: Boolean,
    },
    components: {
        Attachments,
        OfflinePdfDialog,
        AppointmentCard,
        TableOfContents,
        FirstLineCommentNote,
        ServiceManagerCommentNote,
        FinalizedJobBanner,
    },

    data() {
        return {
        }
    },

    mounted() {
    },

    methods: {},

    computed: {
        Customer() {
            return this.job?.Customer;
        },

        InverseParent() {
            return this.job?.InverseParent;
        },

        isBatchJob() {
            return this.job?.InverseParent?.length > 1;
        },

        isFinalized() {
            return this.job?.JobStatus.Id >= 50;
        },
    },

    watch: {},
};
</script>


<style lang="css" scoped>
.v-textarea {
    font-size: 0.9em !important;
}
</style>