<template>
    <v-dialog v-if="!readonly" v-model="dialog" :persistent="mode === 'upload'" width="650">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="primary" small style="text-transform: none;">
                {{ $t('partner.offline_pdf.offline_pdf') }}
            </v-btn>
        </template>
        <v-card class="pa-4 pt-3" style="background-color: #f3f3f3;">
            <v-card-title>
                <v-row>
                    <v-col>
                        <h3>{{ $t('partner.offline_pdf.offline_pdf') }}</h3>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-container v-if="!mode">
                <v-row>
                    <v-col>
                        <a :href="`/api2/Partner/jobs/offlinereport/${job.PartnerJobs[0].Code}`" download="report.pdf"
                            style="text-decoration: none;">
                            <v-card class="d-flex flex-column align-center px-4 py-8 rounded-lg optioncard"
                                elevation="4" height="100%" @click="mode = 'download'">
                                <v-icon v-text="'mdi-download'" color="primary" size="70" class="mb-2" />
                                <h3 class="mb-1">{{ $t('partner.download') }}</h3>
                                <p class="text-center">
                                    <small>
                                        {{ $t('partner.offline_pdf.download_info') }}
                                    </small>
                                </p>
                            </v-card>
                        </a>
                    </v-col>
                    <v-col cols="auto" class="d-flex align-center">
                        {{ $t('partner.or') }}
                    </v-col>
                    <v-col>
                        <v-card class="d-flex flex-column align-center px-4 py-8 rounded-lg optioncard" elevation="4"
                            height="100%" @click="clickFileInput()">
                            <v-icon v-text="'mdi-upload'" color="success" size="70" class="mb-2 mr-3" />
                            <h3 class="mb-1">{{ $t('partner.upload') }}</h3>
                            <p class="text-center">
                                <small>
                                    {{ $t('partner.offline_pdf.upload_info') }}
                                </small>
                            </p>
                            <v-file-input v-show="false" v-model="uploadFile" ref="fileinput" color="primary">
                                <template v-slot:selection="{ index, text }">
                                    <v-chip v-if="index < 2" color="primary" dark label>
                                        {{ text }}
                                    </v-chip>
                                </template>
                            </v-file-input>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
            <v-container v-else-if="mode === 'upload'">
                {{ $t('partner.offline_pdf.uploading') }}
            </v-container>
            <v-container v-else-if="mode === 'download'">
                {{ $t('partner.offline_pdf.downloading') }}
            </v-container>
        </v-card>
    </v-dialog>
</template>


<script>
import http from "@/api/client";

export default {
    name: "OfflinePdfDialog",
    props: {
        job: Object,
        readonly: Boolean,
    },
    components: {
    },

    data() {
        return {
            loading: false,
            dialog: false,
            uploadFile: null,
            mode: null,
        }
    },

    methods: {
        clickFileInput() {
            const elem = this.$refs.fileinput.$refs.input
            elem.click()
        },

        async upload(file) {
            if (file) {
                this.mode = 'upload';
                this.loading = true;
                let formData = new FormData();
                formData.append("Files", file, file.name);
                await http()
                    .post(`api2/partner/jobs/${this.job.Id}/offlinereport`, formData, {
                        headers: { "Content-Type": "multipart/form-data" },
                        transformRequest: formData => formData
                    })
                    .then(() => {
                        this.$emit("Refresh");
                        this.dialog = false;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },

    computed: {},

    watch: {
        dialog: function (val) {
            if (!val) {
                this.uploadFile = null;
                this.mode = null;
            }
        },

        uploadFile: function (val) {
            this.upload(val);
        },
    },
};
</script>


<style lang="css" scoped>
.titlecolor {
    opacity: 0.8;
}

.v-textarea {
    font-size: 0.9em !important;
}
</style>

<!-- hides datatable empty text -->
<style>
.v-data-table__empty-wrapper {
    display: none !important;
}
</style>