<!--DropPad.vue -->
<template>
  <v-sheet v-if="!reducePad" style="background-color: transparent;"
    :style="(height ? ('height: ' + height) : '') + ';' + (width ? ('width: ' + width) : '')">
    <v-card class="d-flex flex-column" id="drop_pad_card" color="transparent"
      :class="[!disabled && highlightActive ? 'highlight' : '', outlined ? 'dashedborder' : '', 'align-' + align, 'justify-' + justify]"
      :style="(height ? ('height: ' + height) : '') + ';' + (width ? ('width: ' + width) : '') + ';' + (disabled ? 'pointer-events: none;' : '')" elevation="0"
      @click="$emit('click')">
      <slot>
        <v-col class="d-flex flex-column align-center justify-center">
          <v-icon size="50">mdi-upload</v-icon>
          <h2>Click or drop file here</h2>
        </v-col>
      </slot>
    </v-card>
    <input type="file" accept="text/xml" style="display:none;" :multiple="multiple ? 'multiple' : undefined" />
  </v-sheet>
</template>


<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    outlined: Boolean,
    height: String,
    width: String,
    align: {
      type: String,
      default: "center",
    },
    justify: {
      type: String,
      default: "center",
    },
  },

  data() {
    return {
      highlightActive: false,
      reducePad: false,
    };
  },

  mounted() {
    var container = this.$el;
    var hiddenInputField = this.$el.lastElementChild;

    if (container) {
      container.addEventListener("dragenter", (event) => {
        event.preventDefault();
        this.highlightActive = true;
      });
      container.addEventListener("dragleave", (event) => {
        event.preventDefault();
        this.highlightActive = false;
      });
      container.addEventListener("dragover", (event) => {
        event.preventDefault();
        this.highlightActive = true;
      });
      container.addEventListener("drop", (event) => {
        event.preventDefault();
        this.highlightActive = false;
        //this.reducePad = true;
        if (event.dataTransfer) {
          if (this.multiple) {
            this.emitFile(event.dataTransfer.files);
          }
          else {
            this.emitFile(event.dataTransfer.files[0]);
          }
        }
      });

      if (hiddenInputField) {
        hiddenInputField.addEventListener("change", (event) => {
          if (event.target.files) {
            if (this.multiple) {
              this.emitFile(event.dataTransfer.files);
            }
            else {
              this.emitFile(event.dataTransfer.files[0]);
            }
          }
        });
      }
    }
  },

  methods: {
    emitFile(file) {
      this.$emit("fileDrop", file);
    },
  },

  computed: {

  },

  watch: {

  },
};
</script>

<style>
#drop_pad_card:hover {
  background-color: rgb(238, 238, 238) !important;
}

#drop_pad_card.dashedborder,
#drop_pad_card.highlight {
  border: 2px dashed rgb(211, 211, 211) !important;
  border-radius: 3px !important;
}

#drop_pad_card.highlight {
  background-color: rgba(170, 0, 255, 0.09) !important;
  border-color: var(--v-tertiary-base) !important;
}
</style>