<template>
    <v-list-item dense style="border-bottom: 1px solid #cccc;" link @click="$emit('click')">
        <v-list-item-icon class="mr-4 my-auto">
            <v-icon>{{ getArticleTypeIcon(article.ArticleType) }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-subtitle>
                {{ article.ArticleNumber }}
            </v-list-item-subtitle>
            <v-list-item-title>
                {{ article.ArticleDescription }}
            </v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>


<script>
export default {
    name: 'ArticleListItem',
    props: {
        article: Object,
        getArticleTypeIcon: Function,
    },
    components: {},

    data() {
        return {};
    },

    methods: {},

    computed: {},

    watch: {},
};
</script>


<style lang='scss' scoped></style>