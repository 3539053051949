<template>
    <v-card :elevation="0" v-if="job">
        <v-card-title>{{ job.DisplayName }}</v-card-title>
        <v-divider />
        <v-row no-gutters class="pt-5" justify="space-between" id="customer">
            <v-col cols="auto" style="min-width: 150px; max-width: 300px;">
                <b>{{ job.Customer.Id }} {{ job.Customer.Name }}</b><br>
                {{ job.CustomerDeliveryAdressStreet }} <br>
                {{ job.CustomerDeliveryAdressZip }} {{ job.CustomerDeliveryAdressCity }} <br>
                {{ job.CustomerDeliveryAdressCountry }}
            </v-col>
            <v-col v-if="job.CustomerPerson" cols="auto" style="min-width: 150px; max-width: 250px;">
                <v-row no-gutters align="center">
                    <v-col cols="0">
                        {{ job.CustomerPerson.Name || "n/a" }}
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" :class="job.CustomerPerson.Mobile ? undefined : 'disabled--text'">
                    <v-col cols="auto">
                        <v-icon size="13" class="pr-1">mdi-phone</v-icon>
                    </v-col>
                    <v-col cols="0" v-if="job.CustomerPerson.Mobile">
                        <a :href="`tel:${job.CustomerPerson.Mobile}`">
                            {{ job.CustomerPerson.Mobile }}
                        </a>
                    </v-col>
                    <v-col cols="0" v-else>
                        n/a
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" :class="job.CustomerPerson.Email ? undefined : 'disabled--text'">
                    <v-col cols="auto">
                        <v-icon size="13" class="pr-1">mdi-email</v-icon>
                    </v-col>
                    <v-col cols="0" v-if="job.CustomerPerson.Email">
                        <a :href="`mailto:${job.CustomerPerson.Email}`">
                            {{ job.CustomerPerson.Email }}
                        </a>
                    </v-col>
                    <v-col cols="0" v-else>
                        {{ $t('partner.na') }}
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-divider class="my-5" />
        <FirstLineCommentNote :comment="job.FirstLineComment">
        </FirstLineCommentNote>
        <div v-for="(machineJob, index) in job.InverseParent" :key="index" :id="machineJob.Machine.Id" class="pt-3">
            <div :style="isBatchJob ? 'border-left: 3px solid #e6e6e6;' : ''" :class="isBatchJob ? 'pl-4' : ''">
                <PreviewMachineListItem :item="machineJob.Machine">
                </PreviewMachineListItem>

                <h5 class="mb-2 pt-4 titlecolor" :id="'comments-' + machineJob.Machine.Id">{{ $t('partner.job.comments')
                    }}</h5>
                <h6 class="titlecolor">{{ $t('partner.job.customer_comment') }}</h6>
                <p class="mb-3 mt-1" :class="machineJob.CustomerComment ? undefined : 'disabled--text'">
                    {{ machineJob.CustomerComment || $t('partner.na') }}
                </p>
            </div>

            <v-divider v-if="(index + 1) < (job.InverseParent.length)" class="my-7"
                style="border-width: 1px; border-color: transparent; opacity: 0.5;">
                <!-- var(--v-primary-base) -->
            </v-divider>
        </div>
    </v-card>
</template>


<script>
import PreviewMachineListItem from "@/components/ListItems/PreviewMachineListItem.vue"
import FirstLineCommentNote from "@/components/Cards/FirstLineCommentNote.vue"

export default {
    name: 'PreviewPartnerJobCard',
    props: {
        job: Object,
    },
    components: {
        PreviewMachineListItem,
        FirstLineCommentNote,
    },
    data() {
        return {};
    },
    computed: {
        isBatchJob() {
            return this.selectedJob?.InverseParent?.length > 1;
        },
    }
};
</script>


<style lang='scss' scoped></style>