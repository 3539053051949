<template>
  <div v-if="isLoggedIn && $store.state.userInfo">
    <SideBarComponent v-if="!isPadBool" :items="navLinks"></SideBarComponent>
    <BottomBarComponent v-else :items="navLinks"></BottomBarComponent>

    <v-main>
      <router-view></router-view>
    </v-main>
  </div>

  <v-row v-else-if="!$store.state.userInfo && $store.state.token" align="center" justify="center"
    style="height: inherit;">
    <v-progress-circular indeterminate size="100" width="4" color="primary">
    </v-progress-circular>
  </v-row>
  <div v-else class="fill-height">
    <login-form></login-form>
  </div>
</template>


<script>
import http from "@/api/client";
import LoginForm from "@/components/Forms/LoginFormPartner.vue";
import BottomBarComponent from "@/components/BottomBarComponent.vue";
import SideBarComponent from "@/components/SideBarComponent.vue";

export default {
  name: "Partner",
  components: {
    "login-form": LoginForm,
    BottomBarComponent,
    SideBarComponent,
  },

  data() {
    return {
      drawerWidth: 130,
      drawer: true,
      loggedin: false,

      jobsview: null,
      jobsLoading: false,
      jobsError: null,

      company: null,
      companyLoading: false,
      companyError: null,
    };
  },

  mounted() {
    this.toggleDisplayChatBtn(true);
    this.updateUserInfo();
    this.setFavicon("faviconPartnerPortal.ico"); //method from main.js
    this.getCompanyDetails();
    this.getJobsView();
  },

  beforeDestroy() {
    this.toggleDisplayChatBtn(false);
    this.setFavicon("favicon.ico"); //method from main.js
    if (typeof window === "undefined") return;
  },

  provide() {
    return {
      jobsWrapper: () => {
        return {
          loading: this.jobsLoading,
          view: this.jobsview,
          error: this.jobsError,
          refreshFunc: this.getJobsView,
        };
      },

      companyWrapper: () => {
        return {
          loading: this.companyLoading,
          item: this.company,
          error: this.error,
          refreshFunc: this.getCompanyDetails,
        };
      },
    }
  },

  methods: {
    updateUserInfo() {
      if (this.$store.state.token && !this.$store.state.userinfo) {
        this.$store.dispatch("getPartnerUserInfo");
      }
    },

    toggleDisplayChatBtn(boolVal) {
      var chatBtn = document.getElementById('chatcontainer');
      chatBtn.style.display = boolVal ? 'block' : 'none';
    },

    async getJobsView() {
      this.jobsLoading = true;
      this.jobsError = null;
      await http()
        .get(`api2/partner/jobs/view`)
        .catch((error) => {
          console.error(error);
          this.jobsview = null;
          if (error.status == 204) {
            this.jobsError = error;
          }
        })
        .then((response) => {
          this.jobsview = response.data;
        })
        .finally(() => {
          this.jobsLoading = false;
        });
    },

    async getCompanyDetails() {
      this.companyLoading = true;
      this.companyError = false;
      await http()
        .get(`api2/partner/company`)
        .catch((error) => {
          console.error(error);
          this.company = null;
          if (error.status == 204) {
            this.companyError = error;
          }
        })
        .then((response) => {
          this.company = response.data;
        })
        .finally(() => {
          this.companyLoading = false;
        });
    },
  },

  computed: {
    userInfo() {
      if (this.$store.state.userInfo) return this.$store.state.userInfo;
      return { Customer: {}, Provider: {} };
    },
    isLoggedIn() {
      return this.$store.state.token != null;
    },

    jobsCount() {
      if (this.jobsview) {
        return (this.jobsview?.Upcoming?.length ?? 0) + (this.jobsview?.Open?.length ?? 0);
      }
      else {
        return 0;
      }
    },

    navLinks() {
      return [
        {
          title: this.$t('partner.dashboard'),
          icon: "mdi-view-dashboard",
          route: { name: "PartnerDashboard" },
        },
        {
          title: this.$t('partner.job.jobs'),
          icon: "mdi-briefcase",
          route: { name: "PartnerJobList" },
          count: this.jobsCount,
        },
        {
          title: this.$t('partner.company.company'),
          icon: "mdi-store",
          route: { name: "ViewPartnerCompany" },
        },
      ];
    },
  },

  watch: {},
};
</script>


<style scoped>
/*
.sidenav.card {
  border-bottom: 1px solid #e0e0e0;
}
*/
/*
.sidenav.card,
.sidenav.card i.v-icon {
  color: #494949 !important;
}
*/
.sidenav.card.router-link-active::before,
.sidenav.card.router-link-active::after {
  content: "";
  position: absolute;
  z-index: 10;
  height: 100%;
  background-color: var(--v-primary-base) !important;
}

.sidenav.card.router-link-active::before {
  width: 7px;
  opacity: 1;
  border-radius: 2px;
}

.sidenav.card.router-link-active::after {
  width: 100%;
  top: 0;
  opacity: 0.08;
}

.sidenav.card.router-link-active,
.sidenav.card.router-link-active i.v-icon {
  color: var(--v-primary-base) !important;
}
</style>

<style lang="scss">
.borderless-input>>>.v-input__slot::before,
.borderless-input .v-input__control .v-input__slot::before {
  border-style: none !important;
}
</style>