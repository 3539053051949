<template>
    <v-container fluid class="pa-0 d-flex justify-center" style="min-height: 100vh; background-color: #e6e6e6;">
        <!-- Loading screen -->
        <v-row v-if="isLoading" align="center" justify="center" style="height:inherit; background-color: white;"
            no-gutters>
            <v-progress-circular indeterminate size="100" width="4" color="primary">
            </v-progress-circular>
        </v-row>

        <!-- Deleted job screen -->
        <v-row v-else-if="selectedJob && selectedJob.JobStatus.Id == 110" align="center" justify="center" no-gutters
            style="background-color: white;">
            <EmptyPage :icon="'mdi-delete-empty'" :title="$t('partner.job.was_deleted_title')"
                :message="$t('partner.job.was_deleted')">
            </EmptyPage>
        </v-row>

        <!-- Normal screen -->
        <v-row v-else-if="selectedJob" no-gutters align="stretch" justify="center">
            <v-col v-show="!isPadBool || showMenu" cols="auto" :style="'width:' + (isPadBool ? '100vw' : '350px')">
                <JobSideMenu v-model="showMenu" :job="selectedJob" :statusLoading="statusLoading"
                    @setStatus="val => setStatus(val)" @Refresh="getJobDetails()">
                </JobSideMenu>
            </v-col>

            <template v-if="isPadBool">
                <v-toolbar style="width: 100%; position: fixed; z-index: 1;">
                    <h3>{{ $t('partner.job.order') }} {{ selectedJob?.Order?.Id }}</h3>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="showMenu = !showMenu">
                        <v-icon>mdi-menu</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-col cols="12">
                    <v-card height="56" width="100%" flat color="transparent">
                        placeholder
                    </v-card>
                </v-col>
            </template>

            <v-spacer v-if="!isPadBool"></v-spacer>
            <v-col v-show="!isPadBool || !showMenu" cols="auto" class="pa-3"
                style="min-height: 100vh; overflow-y: auto;">
                <FinalizedJobBanner v-if="isFinalized && isPadBool" class="mb-2">
                </FinalizedJobBanner>

                <FirstLineCommentNote v-if="isPadBool" :comment="selectedJob.FirstLineComment" closable class="mb-2">
                </FirstLineCommentNote>

                <JobReportImitation v-if="!isLoading" :job="selectedJob" :jobLoading="isLoading">
                </JobReportImitation>
            </v-col>
            <v-spacer v-if="!isPadBool"></v-spacer>
        </v-row>

        <!-- Error screen -->
        <v-row v-else align="center" justify="center" no-gutters style="background-color: white;">
            <!-- Job should not be shown to user -->
            <EmptyPage v-if="unauthorizedAccess" :icon="'mdi-shield-account'"
                :title="$t('partner.job.unauthorized_job_title')" :message="$t('partner.job.unauthorized_job')">
            </EmptyPage>
            <!-- Job has not activated (started) yet -->
            <EmptyPage v-else-if="jobNotStarted" :icon="'mdi-pause-octagon'" :title="$t('partner.job.start_job_title')"
                :message="'Do you want to start this job?'">
                <template v-slot:actions>
                    <v-btn color="primary" @click="setStatus('Open')">
                        <v-icon size="20" class="mr-2">mdi-play</v-icon>
                        {{ $t('partner.job.start_job') }}
                    </v-btn>
                </template>
            </EmptyPage>
            <!-- Unexpected errors -->
            <EmptyPage v-else :icon="'mdi-alert'" :title="$t('partner.job.error_loading_job_title')"
                :message="$t('partner.job.error_loading_job')">
            </EmptyPage>
        </v-row>

    </v-container>
</template>


<script>
import http from "@/api/client";
import JobReportImitation from "./JobReportImitation.vue";
import JobSideMenu from "./JobSideMenu.vue";
import EmptyPage from "@/views/EmptyPage.vue";
import FirstLineCommentNote from "@/components/Cards/FirstLineCommentNote.vue";
import FinalizedJobBanner from "@/components/Cards/FinalizedJobBanner.vue";


export default {
    name: "ViewJob",
    components: {
        JobReportImitation,
        JobSideMenu,
        EmptyPage,
        FirstLineCommentNote,
        FinalizedJobBanner,
    },

    data() {
        return {
            selectedJob: {},
            error: null,
            isLoading: true,
            statusLoading: false,
            unauthorizedAccess: false,
            jobNotStarted: false,
            showMenu: false,
        }
    },

    mounted() {
        this.getJobDetails();
    },

    methods: {
        async getJobDetails() {
            this.isLoading = true;
            this.unauthorizedAccess = false;
            this.jobNotStarted = false;
            this.error = null;
            await http()
                .get(`api2/partner/jobs/${this.jobId}`)
                .then((response) => {
                    this.selectedJob = response.data;
                    for (let machineJob of this.selectedJob.InverseParent) {
                        machineJob.editTechnicianCommentValue = machineJob.TechnicianComment;
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.selectedJob = null;
                    this.error = error;
                    if (error.response.status == 403) {
                        this.unauthorizedAccess = true;
                    }
                    else if (error.response.status == 409) {
                        this.jobNotStarted = true;
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        async setStatus(status) {
            this.statusLoading = true;
            await http()
                .patch(`api2/partner/jobs/${this.jobId}/SetStatus/${status}`)
                .then(() => {
                    this.statusLoading = false;
                    this.getJobDetails();
                });
        },
    },

    computed: {
        jobId() {
            return this.$route.params.id;
        },
        isFinalized() {
            return this.selectedJob?.JobStatus.Id >= 50;
        },
    },

    watch: {},
};
</script>


<style lang="css" scoped>
.titlecolor {
    opacity: 0.8;
}

.v-textarea {
    font-size: 0.9em !important;
}
</style>

<!-- hides datatable empty text -->
<style>
.v-data-table__empty-wrapper {
    display: none !important;
}

.v-data-table__mobile-row {
    padding: 0px !important;
}
</style>