<template>
    <v-container fluid>
        <DxChart id="chart" ref="chart" palette="Bright" title="">
            <DxTooltip :enabled="true" />
            <DxAdaptiveLayout :width="450" />
            <DxSize :height="200" />
            <DxCommonSeriesSettings type="bar" />
        </DxChart>

        <DxPivotGrid id="pivotgrid" ref="grid" :data-source="dataSource" :allow-sorting-by-summary="false"
            :allow-filtering="false" :show-borders="true" :show-column-grand-totals="false"
            :show-row-grand-totals="false" :show-row-totals="true" :show-column-totals="true" :allow-sorting="false">
            <DxFieldChooser :enabled="false" :height="400" />
        </DxPivotGrid>
    </v-container>
</template>

<script>
import { DxAdaptiveLayout, DxChart, DxCommonSeriesSettings, DxSize, DxTooltip } from "devextreme-vue/chart";
import { DxFieldChooser, DxPivotGrid } from "devextreme-vue/pivot-grid";

export default {
    name: "PartnerCostGraph",
    props: ["data"],
    components: {
        DxChart,
        DxAdaptiveLayout,
        DxCommonSeriesSettings,
        DxPivotGrid,
        DxFieldChooser,
        DxSize,
        DxTooltip,
    },
    methods: {
        customizeTooltip(args) {
            return args.seriesName;
        },
        bind() {
            const data = {
                fields: [
                    {
                        caption: this.$t('partner.month'),
                        width: 120,
                        dataField: "date",
                        area: "row",
                        sortBySummaryField: "NOK",
                    },
                    {
                        caption: this.$t('partner.week'),
                        dataField: "week",
                        width: 150,
                        area: "row",
                    },
                    {
                        caption: this.$t('partner.user'),
                        dataField: "Entry.User.Name",
                        width: 150,
                        area: "column",
                    },
                    {
                        caption: this.$t('partner.job.customer'),
                        dataField: "Entry.Job.Customer.Name",
                        width: 150,
                        area: "row",
                    },
                    {
                        groupName: "date",
                        groupInterval: "month",
                        visible: false,
                    },
                    {
                        caption: "NOK",
                        dataField: "Cost",
                        dataType: "number",
                        summaryType: "sum",
                        format: {
                            type: "currency",
                            precision: 2,
                            currency: "NOK"//this.$store.state.GlobalSettings.CurrencyFormat
                        },
                        area: "data",
                    },
                ],
                store: this.data.map(v => {
                    v.sort = v.Month;
                    const monthName = new Date(2024, v.Month - 1).toLocaleString('default', { month: 'long' });
                    v.date = `${monthName[0].toUpperCase()}${monthName.slice(1).toLowerCase()}`
                    v.week = `${this.$t('partner.week')} ${v.Week}`
                    return v;
                })
            }

            const pivotGrid = this.$refs.grid.instance;
            const chart = this.$refs.chart.instance;
            pivotGrid.bindChart(chart, {
                dataFieldsDisplayMode: "splitPanes",
                alternateDataFields: false,
            });

            this.dataSource = data;
        }
    },
    data() {
        return {
            dataSource: null,
        };
    },
    mounted() {
        this.bind();
    },
    computed: {
    }
};
</script>

<style scoped></style>