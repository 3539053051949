import Axios from "axios";
//import router from '@/router'
import store from "@/store";
export default () => {

  var client = Axios.create({
    baseURL: "/",
    //      process.env.NODE_ENV == "production" ? "/" : "http://localhost:5000",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  client.defaults.timeout = 30000;
  client.interceptors.request.use(
    (config) => {
      const token =
        store.state.token != null ? store.state.token.access_token : null;
      if (token) {
        config.headers.common["Authorization"] = "Bearer " + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  client.interceptors.response.use(
    (response) => {
      if (response.status === 200 || response.status === 201) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    },
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status) {
        console.error("Request returned error statuscode", Object.assign({}, error));
        switch (error.response.status) {
          case 400:
            //do something
            break;
          case 401:

            if (error.response.status === 401 && !originalRequest._retry) {
              originalRequest._retry = true;
              try {
                const data = await refreshToken();
                console.log("Retransmitting original request command", originalRequest)
                store.dispatch("updateToken", data);
                originalRequest.headers["Authorization"] = 'Bearer ' + data.access_token;
                var newRequest = Axios(originalRequest);
                return Promise.resolve(newRequest);
              }
              catch (error) {
                console.log("Logging out:", error);
                store.dispatch("logout");
              }
            }
            else {
              store.dispatch("logout");
            }
            break;
          default:
            store.dispatch("snackbar",error?.response?.data?.Message ?? "Unknown error");
          break;
        }
        return Promise.reject(error);
      }
    }
  );
  return client;
};

async function refreshToken() {
  const token = store.state.token != null ? store.state.token.refresh_token : null;
  var refreshTokenClient = Axios.create({
    baseURL: "/",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
  });

  var response = await refreshTokenClient.post("/api2/token/refresh");
  return response.data;
}