<template>
    <v-dialog v-model="addDialog" :width="500">
        <template v-slot:activator="{ on }">
            <v-btn color="green" dark outlined width="100%" v-on="on" @click="showAddDialog()">
                <v-icon>mdi-plus</v-icon>
                {{ $t('partner.order.new_line') }}
            </v-btn>
        </template>

        <v-card v-if="articleSelect">
            <v-form @submit.prevent="addItem">
                <v-card-title>
                    <v-btn icon class="mr-3" @click="articleSelect = null">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    {{ $t('partner.order.set_line_details') }}
                </v-card-title>
                <v-divider />
                <v-card-text text-center class="mt-2">
                    <v-text-field v-model="articleSelect.ArticleDescription"
                        :label="$t('partner.order.custom_line_text')" :counter="50" maxlength="50" outlined dense>
                    </v-text-field>
                    <v-text-field v-model="articleSelect.ArticleCount" :label="$t('partner.order.count')" type="number"
                        outlined dense autofocus>
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn outlined @click="addDialog = false">{{ $t('partner.cancel') }}</v-btn>
                    <v-spacer />
                    <v-btn type="submit" color="primary" :elevation="0" :loading="loading">
                        {{ $t('partner.save') }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>

        <v-card v-else>
            <v-card-title>
                {{ $t('partner.order.pick_line_type') }}
            </v-card-title>
            <v-divider />
            <Search v-if="allowSearch" v-model="searchword" :placeholder="$t('partner.order.search_article')"
                class="mx-4 mt-3 mb-2" :dense="false" :loading="loading">
            </Search>
            <v-list class="py-0"
                style="max-height: calc(80vh - 150px); min-height: calc(55vh - 150px); overflow-y: auto;">
                <ArticleListItem v-for="(article, index) in articles" :key="'article-' + index" :article="article"
                    :getArticleTypeIcon="getArticleTypeIcon" @click="selectArticle(article)">
                </ArticleListItem>
                <p v-if="!articles.length" class="ma-10 text-center">{{ $t('partner.order.no_articles_found') }}</p>
            </v-list>
        </v-card>
    </v-dialog>
</template>


<script>
import http from "@/api/client";
import Search from "@/components/Inputs/Search.vue";
import ArticleListItem from "@/components/ListItems/ArticleListItem.vue";

export default {
    name: 'OrderLineDialog',
    props: {
        machineJobId: Number,
        getArticleTypeIcon: Function,
    },
    components: {
        Search,
        ArticleListItem,
    },
    inject: {
        companyWrapper: { //set up as a function to be returned in computed to be reactive
            type: Function,
            default: () => { },
        },
    },

    data() {
        return {
            articleSelect: null,
            articleSelectQuantity: 1,
            articleSelectDescription: null,
            addDialog: false,
            loading: false,
            newArticle: {
                Id: null,
                IsTimeArticle: false,
                Description: '',
                Amount: 1,
            },
            articles: [],
            copy: [],
            searchword: '',
            searcreults: [],
        };
    },

    mounted() {
        this.getArticles();
    },

    methods: {
        showAddDialog() {
            this.searchword = '';
            this.articleSelect = null;
            this.articleSelectDescription = null;
            this.articleSelectQuantity = 1;
            this.addDialog = true;
        },

        getArticles() {
            this.loading = true;
            return http()
                .get(`api2/partner/Article`)
                .then((response) => {
                    this.articles = response?.data ?? [];
                    this.copy = Object.assign([], this.articles);
                    this.loading = false;
                });
        },

        searchArticles(query) {
            this.loading = true;
            return http()
                .get(`api2/partner/Article/Search`, { params: { searchword: query } })
                .then((response) => {
                    this.articles = response?.data ?? [];
                    this.loading = false;
                });
        },

        selectArticle(article) {
            this.articleSelect = Object.assign({}, article);
            this.articleSelect.ArticleCount = 1;
        },

        addItem() {
            this.loading = true;
            this.saveOrderLine(this.articleSelect)
                .finally(() => {
                    this.addDialog = false;
                    this.$emit('added');
                })
        },

        saveOrderLine(obj) {
            const dto = Object.assign({}, obj);
            return http()
                .post(`api2/partner/OrderDetail/${this.machineJobId}`, dto)
                .then(() => {
                    this.loading = false;
                });
        },
    },

    computed: {
        reactiveCompanyWrapper() {
            return this.companyWrapper();
        },
        company() {
            return this.reactiveCompanyWrapper.item;
        },
        allowSearch() {
            return this.company.LookupPartsFromWarehouseId;
        },
    },

    watch: {
        searchword(val) {
            if (!val || val.length < 3) {
                this.articles = Object.assign([], this.copy);
                return;
            }
            else {
                this.searchArticles(val);
            }
        },
    },
};
</script>


<style lang='scss' scoped></style>