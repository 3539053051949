<template>
    <div>
        <h5 class="mb-2 titlecolor">{{ $t('partner.job.counters') }}</h5>

        <v-data-table v-if="!isPhoneBool" dense :headers="counterTableHeaders" :items="counters" hide-default-footer
            disable-pagination item-key="Id">

            <template v-slot:item.Value="{ item }">
                <v-text-field v-if="!readonly" v-model="item.editValue" :loading="item.loading" single-line outlined
                    dense hide-details="auto" clearable class="mt-0 pt-0" :error="item.errors && item.errors.length > 0"
                    :error-messages="item.errors" @blur="setCounter(item)">
                </v-text-field>
                <template v-else>
                    {{ item.Value }}
                </template>
            </template>
        </v-data-table>

        <v-row v-else no-gutters>
            <v-col v-for="(item, index) in counters" :key="'counter-' + index" cols="12">
                <b class="mb-1 titlecolor" v-text="item.Name" />
                <v-text-field v-if="!readonly" v-model="item.editValue" :loading="item.loading" single-line outlined
                    dense :label="item.Name" :hint="item.LastValue ? ('Was ' + item.LastValue) : undefined"
                    :placeholder="item.LastValue" persistent-placeholder clearable class="mt-0 pt-0"
                    :error="item.errors && item.errors.length > 0" :error-messages="item.errors"
                    @blur="setCounter(item)">
                </v-text-field>
            </v-col>
        </v-row>

        <v-progress-linear v-if="loading" indeterminate color="primary" height="1">
        </v-progress-linear>
    </div>
</template>


<script>
import Vue from "vue";
import http from "@/api/client";

export default {
    name: "machine-counters",
    components: {
    },
    props: {
        machineid: {
            type: Number,
            required: true
        },
        readonly: Boolean,
    },

    data() {
        return {
            loading: false,
            showCounters: true,
            counters: [],

            counterTableHeaders: [
                { text: this.$t('partner.job.counter_name'), value: 'Name', sortable: false, align: 'start' },
                { text: this.$t('partner.job.counter_todays_value'), value: 'Value', sortable: false, align: 'start' },
                { text: this.$t('partner.job.counter_previous_value'), value: 'LastValue', sortable: false, align: 'end' },
            ],
        }
    },

    mounted() {
        this.getCounters();
    },

    methods: {
        getCounters() {
            this.loading = true;
            return http()
                .get(`api2/partner/machinecounter/${this.machineid}`)
                .then((response) => {
                    this.counters = response.data;
                    for (let counter of this.counters) {
                        counter.editValue = counter.Value;
                    }
                    this.loading = false;
                });
        },

        async setCounter(counter) {
            if (counter.editValue != counter.Value) {
                counter.Value = counter.editValue;
                Vue.set(counter, 'loading', true);
                await http()
                    .put(`api2/partner/machinecounter/`, counter)
                    .then(() => {
                        counter.errors = null;
                    })
                    .catch((error) => {
                        if (error.status != 204) {
                            console.error(error);
                            counter.errors = 'Error saving value.';
                        }
                    })
                    .finally(() => {
                        Vue.set(counter, 'loading', false);
                    });
            }
        },
    },

    computed: {},

    watch: {},
};
</script>


<style lang="css" scoped></style>