<template>
    <v-list-item-subtitle :class="appointmentColor + '--text'">
        <v-icon size="16" class="mr-2" :color="appointmentColor">mdi-calendar</v-icon>
        {{ startTime }} - {{ endTime }}, {{ date }}
    </v-list-item-subtitle>
</template>


<script>
export default {
    name: 'AppointmentSubtitle',
    props: {
        appointments: Array,
    },
    components: {},

    data() {
        return {};
    },

    methods: {
        getClosestAppointment() {
            var list = Object.assign([], this.appointments);
            var today = new Date();
            list.sort(function (a, b) {
                var distancea = Math.abs(today - new Date(a.AppointmentDate));
                var distanceb = Math.abs(today - new Date(b.AppointmentDate));
                return distancea - distanceb;
            });
            return list[0];
        }
    },

    computed: {
        startTime() {
            return new Date(this.appointment.StartTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        },
        endTime() {
            return new Date(this.appointment.EndTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        },
        date() {
            return new Date(this.appointment.StartTime).toLocaleDateString();
        },
        appointmentColor() {
            var diff = new Date(this.appointment.AppointmentDate) - new Date();
            var diffInDays = diff / (1000 * 60 * 60 * 24); 
            //console.log(diffInDays, new Date(this.appointment.AppointmentDate));
            
            if (diffInDays > 5) {
                return 'primary';
            }
            else if (diffInDays < 0) {
                return 'disabled';
            }
            else {
                return 'warning';
            }
        },
        appointment() {
            return this.getClosestAppointment();
        },
    },

    watch: {},
};
</script>


<style lang='scss' scoped></style>