<template>
  <v-container fluid style="height: 100vh">

    <!-- shows full loading page when first fetching info -->
    <v-row v-if="loading && !company" align="center" justify="center" style="height: inherit;">
      <v-progress-circular indeterminate size="100" width="4" color="primary">
      </v-progress-circular>
    </v-row>

    <v-row v-else-if="company" align="stretch" style="height: inherit;">
      <!-- shows linear loader on top when just refreshing info -->
      <v-progress-linear v-if="loading" absolute indeterminate color="primary" height="3">
      </v-progress-linear>
      <v-col :cols="isPhoneBool ? 12 : 6">
        <h3 v-text="$t('partner.company.invoicing_details')" class="mx-3" />
        <v-container fluid>
          <v-row>
            <v-col cols=" 12">
              <SimpleField :label="$t('partner.company.orgnr')" :value="company.OrganisationNumber">
              </SimpleField>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <SimpleField :label="$t('partner.company.name')" :value="company.CompanyName">
              </SimpleField>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <SimpleField :label="$t('partner.company.invoice_email')" :value="company.InvoiceEmailAddress">
              </SimpleField>
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col :cols="isPhoneBool ? 12 : 6" style="background-color: rgb(248, 248, 248) !important;">
        <v-row no-gutters>
          <v-col cols="12">
            <h3 v-text="$t('partner.company.address')" class="mx-3" />
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <SimpleField :label="$t('partner.company.street')" :value="company.AddressStreet">
                  </SimpleField>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <SimpleField :label="$t('partner.company.zip')" :value="company.AddressPostalCode">
                  </SimpleField>
                </v-col>
                <v-col cols="9">
                  <SimpleField :label="$t('partner.company.city')" :value="company.AddressCity">
                  </SimpleField>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>

        <v-divider class="my-4"></v-divider>

        <v-row no-gutters>
          <v-col cols="12">
            <h3 v-text="$t('partner.company.contact_person')" class="mx-3" />
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <SimpleField :label="$t('partner.company.contact_name')" :value="company.ManagerName">
                  </SimpleField>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <SimpleField :label="$t('partner.company.contact_email')" :value="company.ManagerEmail">
                  </SimpleField>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <SimpleField :label="$t('partner.company.contact_phone')" :value="company.ManagerPhone">
                  </SimpleField>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-else-if="noCompanyOnUser" align="center" justify="center" style="height: inherit;">
      <EmptyPage :icon="'mdi-account-question'" :title="$t('partner.company.no_company_on_user_title')">
        <template v-slot:message>
          {{ $t('partner.company.no_company_on_user') }}
        </template>
      </EmptyPage>
    </v-row>

    <v-row v-else align="center" justify="center" style="height: inherit;">
      <EmptyPage :icon="'mdi-alert'" :title="$t('partner.company.error_loading_company_title')"
        :message="$t('partner.company.error_loading_company')">
      </EmptyPage>
    </v-row>

  </v-container>
</template>


<script>
import SimpleField from '@/components/SimpleField.vue';
import EmptyPage from "@/views/EmptyPage.vue";

export default {
  name: 'ViewPartnerCompany',
  props: {},
  inject: {
    companyWrapper: { //set up as a function to be returned in computed to be reactive
      type: Function,
      default: () => { },
    },
  },
  components: {
    SimpleField,
    EmptyPage,
  },

  data() {
    return {
    };
  },

  mounted() {
    this.reactiveCompanyWrapper.refreshFunc();
  },

  methods: {},

  computed: {
    reactiveCompanyWrapper() {
      return this.companyWrapper();
    },
    loading() {
      return this.reactiveCompanyWrapper.loading;
    },
    company() {
      return this.reactiveCompanyWrapper.item;
    },
    noCompanyOnUser() {
      return this.reactiveCompanyWrapper.item == null && !this.reactiveCompanyWrapper.error;
    },
  },

  watch: {},
};
</script>


<style lang='scss' scoped></style>
