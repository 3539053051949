<template>
  <v-bottom-navigation app width="100vw" class="bottom-bar spotlight" flat>
    <NavTileComponent v-for="item in items" :key="item.route.name" :route="item.route" :title="item.title" :icon="item.icon" :count="item.count"
      asButton>
    </NavTileComponent>
  </v-bottom-navigation>
</template>


<script>
import NavTileComponent from "@/components/NavTileComponent.vue";

export default {
  name: "BottomBar",
  props: { items: Array },
  components: {
    NavTileComponent,
  },

  data() {
    return {
    };
  },

  mounted() { },

  methods: {},

  computed: {
    userInfo() {
      if (this.$store.state.userInfo) return this.$store.state.userInfo;
      return { Customer: {}, Provider: {} };
    },

    shoppingCartCount() {
      return this.$store.getters.shoppingCartCount;
    },
  },

  watch: {},
};
</script>

<style lang="scss" scoped>



</style>