<template>
  <v-container v-if="orgLoading" fluid style="width: 100%; height: 100vh;" class="d-flex align-center justify-center">
    <v-progress-circular indeterminate size="100" width="4" color="primary">
    </v-progress-circular>
  </v-container>

  <v-container v-else fluid>
    <v-row>
      <OrganizationHeader :organization="organization" :loading="orgLoading" />
    </v-row>

    <v-row>
      <v-col :cols="isPhoneBool ? 12 : 6">
        <AccountList>
        </AccountList>
      </v-col>

      <v-col :cols="isPhoneBool ? 12 : 6">
        <ContactList>
        </ContactList>
      </v-col>
    </v-row>

  </v-container>
</template>


<script>
import http from "@/api/client";
import OrganizationHeader from "@/components/OrganizationHeader.vue";
import ContactList from "@/components/ContactList.vue";
import AccountList from "@/components/AccountList.vue";

export default {
  name: "Organization",
  components: {
    OrganizationHeader,
    ContactList,
    AccountList,
  },

  data() {
    return {
      organization: {},
      accounts: [],
      orgLoading: false,
      usersLoading: false,
    };
  },

  mounted() {
    this.getOrg();
  },

  methods: {
    async getOrg() {
      this.orgLoading = true;
      await http()
        .get("api2/customer/userinfo/organization")
        .then((response) => {
          this.organization = response.data;
        });
      this.orgLoading = false;
    },
  },

  computed: {},

  watch: {},
};
</script>

<style>
.expanded {}

.collapsed {
  max-height: 90px;
  overflow-y: hidden;
}
</style>