<template>
  <v-list-item>
    <v-list-item-icon v-if="!hideImage" class="ma-0 mr-2">
      <MachineModelImage :size="128"
        :relativePath="item && (item.ModelImage || item.MachineModel && item.MachineModel.ModelImage)">
      </MachineModelImage>
    </v-list-item-icon>
    <v-list-item-content class="py-1">
      <v-list-item-title>
        {{ machineGeneralizerText(item) }}
      </v-list-item-title>
      <v-list-item-title class="primary--text">
        {{ machineIdentifierText(item) }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>


<script>
import MachineModelImage from "@/components/Cards/MachineModelImage.vue";

export default {
  name: "PreviewMachineListItem",
  props: {
    item: Object,
    hideImage: Boolean,
    showAlerts: Boolean,
    showOpenBtn: Boolean,
  },
  components: {
    MachineModelImage,
  },

  data() {
    return {
    };
  },

  mounted() { },

  methods: {
    openMachine(machine) {
      this.$router.push({ name: 'ViewMachine', params: { id: machine.Id } });
    },
  },

  computed: {},

  watch: {},
};
</script>


<style lang='scss' scoped></style>