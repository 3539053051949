<template>
    <v-list-item :dense="!isPhoneBool" link class="pr-2" style="border-bottom: 1px solid rgba(0, 0, 0, 0.04);" @click="$emit('click')">
        <v-list-item-content :class="isPhoneBool ? '' : 'py-0'">
            <v-list-item-title>
                {{ item.DisplayName }}
            </v-list-item-title>
            <AppointmentSubtitle v-if="isPhoneBool" :appointments="item.Appointments" class="mt-1" />
        </v-list-item-content>
        <v-list-item-content v-if="!isPhoneBool">
            <AppointmentSubtitle :appointments="item.Appointments" />
        </v-list-item-content>
        <v-list-item-action class="ma-auto ml-2">
            <v-btn icon>
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </v-list-item-action>
    </v-list-item>
</template>


<script>
import AppointmentSubtitle from './AppointmentSubtitle.vue';

export default {
    name: 'SimpleJobListItem',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    components: {
        AppointmentSubtitle,
    },

    data() {
        return {};
    },

    methods: {

    },

    computed: {

    },

    watch: {},
};
</script>


<style lang='scss' scoped></style>