<template>
    <div>
        {{ $t('partner.job.table_of_contents') }}
        <ul style="max-height: 330px; overflow-y: auto;">
            <li>
                <a :href="`#customer`" style="text-decoration: none;">
                    {{ $t('partner.job.customer') }}
                </a>
            </li>
            <component :is="isBatchJob ? 'li' : 'div'" v-for="(machineJob, index) in InverseParent" :key="index + '-'">
                <a v-if="isBatchJob" :href="`#${machineJob.Machine.Id}`" style="text-decoration: none;">
                    {{ machineJob.Machine.DisplayName }}
                </a>
                <ul>
                    <li>
                        <a :href="`#comments-${machineJob.Machine.Id}`" style="text-decoration: none;">
                            {{ $t('partner.job.comments') }}
                        </a>
                    </li>
                    <li>
                        <a :href="`#counters-${machineJob.Machine.Id}`" style="text-decoration: none;">
                            {{ $t('partner.job.counters') }}
                        </a>
                    </li>
                    <li>
                        <a :href="`#articles-${machineJob.Machine.Id}`" style="text-decoration: none;">
                            {{ $t('partner.order.articles') }}
                        </a>
                    </li>
                </ul>
            </component>
        </ul>
    </div>
</template>


<script>
export default {
    name: 'TableOfContents',
    props: {
        job: Object,
    },
    components: {},

    data() {
        return {};
    },

    methods: {},

    computed: {
        isBatchJob() {
            return this.job?.InverseParent?.length > 1;
        },

        InverseParent() {
            return this.job?.InverseParent;
        },
    },

    watch: {},
};
</script>


<style lang='scss' scoped></style>