<template>
    <v-card v-if="comment" class="my-4 pa-3 rounded-0" color="rgb(255, 209, 189)" style="font-size: 12.5px;">
        <b>{{ $t('partner.job.service_manager_comment') }}</b><br>
        {{ comment }}
    </v-card>
</template>


<script>
export default {
    name: 'ServiceManagerCommentNote',
    props: {
        comment: String,
    },
    components: {},

    data() {
        return {};
    },

    methods: {},

    computed: {},

    watch: {},
};
</script>


<style lang='scss' scoped></style>