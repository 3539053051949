<template>
  <v-container fluid class="pa-0">
    <v-container>
      <v-row>
        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="4" md="4">
              <v-card :elevation="0">
                <v-card-title>
                  {{ overview?.AllTimeCountPlanned }}
                </v-card-title>
                <v-card-subtitle>
                  <router-link :to="{ name: 'PartnerJobList' }">
                    {{ $t('partner.job.planned') }}
                  </router-link>
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="4" md="4">
              <v-card :elevation="0">
                <v-card-title>
                  {{ overview?.AllTimeCountActive }}
                </v-card-title>
                <v-card-subtitle>
                  <router-link :to="{ name: 'PartnerJobList' }">
                    {{ $t('partner.job.open') }}
                  </router-link>
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="4" md="4">
              <v-card :elevation="0">
                <v-card-title>
                  {{ overview?.AllTimeCountCompleted }}
                </v-card-title>
                <v-card-subtitle>
                  <router-link :to="{ name: 'PartnerJobList' }">
                    {{ $t('partner.job.completed') }}
                  </router-link>
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
          <v-divider />
          <v-row>
            <v-col cols="4" md="4">
              <v-card :elevation="0">
                <v-card-title>
                  {{ overview?.CurrentMonthCost }} NOK
                </v-card-title>
                <v-card-subtitle>
                  {{ $t('partner.job.this_month') }}
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="8" md="8">
              <v-card :elevation="0">
                <v-card-title>
                  {{ overview?.CurrentMonthCount }}
                </v-card-title>
                <v-card-subtitle>
                  {{ $t('partner.job.completed_this_month') }}
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12" v-if="overview">
          <PartnerCostGraph :data="overview.PartnerOverviewEntries"></PartnerCostGraph>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>


<script>
import http from "@/api/client";
import PartnerCostGraph from './PartnerCostGraph.vue'

export default {
  name: "PartnerDashboard",
  components: {
    PartnerCostGraph
  },

  data() {
    return {
      overview: null,
      dataSource: null,
    };
  },

  mounted() {
    http()
      .get("api2/partner/overview")
      .then((response) => {
        this.overview = response.data;
      });
  },

  methods: {
    customizeTooltip(args) {
      return args.seriesName;
    }
  },

  computed: {},

  watch: {},
};
</script>

<style scoped></style>