<template>
  <v-container>
    <PreviewPartnerJobCard class="px-2 pb-2" :job="job">
    </PreviewPartnerJobCard>
    <v-card :elevation="0" class="mt-1" v-if="!isPhoneBool">
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" :elevation="0" @click="start()">{{ $t('partner.job.start_job') }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-btn v-else color="primary" :elevation="0" fab fixed bottom right @click="start()">
      <v-icon>mdi-play</v-icon>
    </v-btn>
    <StartJobDialog :opentrigger="dialogTrigger" :jobId="job?.Id">
    </StartJobDialog>
  </v-container>
</template>


<script>
import http from "@/api/client";
import PreviewPartnerJobCard from "@/components/Cards/PreviewPartnerJobCard.vue"
import StartJobDialog from "@/components/Dialogs/StartJobDialog.vue";

export default {
  name: "PartnerCodeJob",
  components: {
    PreviewPartnerJobCard,
    StartJobDialog,
  },

  data() {
    return {
      dialogTrigger: 1,
      job: null,
    }
  },

  mounted() {
    var code = this.$route.params.code;
    http().get("/api2/partner/jobs/code", { params: { code } })
      .then(response => {
        this.job = response.data;
      });
  },

  methods: {
    start() {
      this.dialogTrigger++;
    }
  },

  computed: {},

  watch: {},
};
</script>


<style scoped></style>