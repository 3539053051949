<template>
    <v-container fluid class="pa-0 my-5">
        <v-row no-gutters align="center">
            <v-col cols="auto">
                <h6>{{ $t('partner.job.attachments') }}</h6>
            </v-col>
            <v-col cols="0" class="px-3">
                <v-progress-linear v-if="loading" indeterminate color="primary" height="2">
                </v-progress-linear>
                <v-divider v-else>
                </v-divider>
            </v-col>
            <v-col v-if="!loading" cols="auto">
                <v-btn color="green" dark small icon @click="clickFileInput()">
                    <v-icon size="20">mdi-paperclip-plus</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <DropPad width="100%" align="stratch" justify="start" multiple @fileDrop="handleFileDrop"
            @click="clickFileInput()">
            <v-file-input v-show="false" v-model="uploadFiles" ref="fileinput" color="primary" multiple="multiple">
                <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" color="primary" dark label>
                        {{ text }}
                    </v-chip>
                </template>
            </v-file-input>

            <v-list v-if="files && files.length" class="py-0" @click.stop="">
                <File v-for="(file, index) in files" :key="'file-' + index" :file="file" :deleteFunc="deleteAttachment">
                </File>
            </v-list>

            <div v-else-if="!loading">
                {{ $t('partner.job.no_attachments') }}
            </div>
        </DropPad>
    </v-container>
</template>


<script>
import http from "@/api/client";
import File from "@/components/ListItems/File.vue";
import DropPad from "@/components/Cards/DropPad.vue";

export default {
    name: 'Attachments',
    props: {
        jobId: Number,
        readonly: Boolean,
    },
    components: {
        File,
        DropPad,
    },

    data() {
        return {
            uploadFiles: null,
            files: [],
            loading: false,
        };
    },

    mounted() {
        this.getAttachments();
    },

    methods: {
        clickFileInput() {
            const elem = this.$refs.fileinput.$refs.input
            elem.click()
        },

        handleFileDrop(files) {
            if (!this.readonly) {
                this.uploadFiles = files;
            }
        },

        getAttachments() {
            if (this.jobId) {
                this.loading = true;
                return http()
                    .get(`api2/partner/jobs/${this.jobId}/Attachments`)
                    .then((response) => {
                        this.files = response.data?.Files;
                        this.loading = false;
                    });
            }
        },

        async uploadAttachments() {
            var files = this.uploadFiles;
            if (files && files.length) {
                this.loading = true;
                let formData = new FormData();
                for (var filekey in files) {
                    if (Object.prototype.hasOwnProperty.call(files, filekey)) {
                        const file = files[filekey];
                        formData.append("Files", file, file.name);
                    }
                }
                await http()
                    .post(`api2/partner/jobs/${this.jobId}/Attachments`, formData, {
                        headers: { "Content-Type": "multipart/form-data" },
                        transformRequest: formData => formData
                    })
                    .then((response) => {
                        this.files = response.data?.Files;
                    })
                    .finally(() => {
                        this.uploadFiles = null;
                        this.loading = false;
                    });
            }
        },

        deleteAttachment(file) {
            this.loading = true;
            return http()
                .delete(`api2/partner/jobs/${this.jobId}/Attachment/${file.Id}`)
                .then((response) => {
                    this.files = response.data?.Files;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },

    computed: {},

    watch: {
        jobId() {
            this.getAttachments();
        },

        uploadFiles: function (val) {
            this.uploadAttachments(val);
        },
    },
};
</script>


<style lang='scss' scoped></style>