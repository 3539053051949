<template>
    <v-row no-gutters align="center" class="hoverlistitem">
        <v-col cols="11">
            <v-list-item dense style="min-height: 34px; max-width: calc(100% - 12px);" link :href="file.Link"
                target="_blank" class="px-1">
                <v-list-item-icon class="my-auto mr-2" style="min-width: unset;">
                    <!-- 
                            <v-img v-if="file.IsMedia2" class="ma-2" :width="80" :src="file.Link">
                            </v-img>
                        -->
                    <v-icon size="16">{{ typeIcon() }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="py-0">
                    <v-list-item-title>
                        {{ file.Name }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-col>
        <v-col cols="1" v-if="deleteFunc != undefined" class="hiddenBeforeHoverContent">
            <v-btn icon small @click.stop="deleteFunc(file)">
                <v-icon size="20">mdi-close</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>


<script>
export default {
    name: 'File',
    props: {
        file: Object,
        deleteFunc: Function,
    },
    components: {},

    data() {
        return {
            DocumentTypes: {
                LINK: 1,
                FORM: 2,
                PARTDRAWING: 3,
            },
        };
    },

    methods: {
        typeIcon() {
            if (this.file.IsMedia) {
                return 'mdi-file-image';
            }
            else if (this.file.TypeId == this.DocumentTypes.FORM) {
                return 'mdi-file-document-edit';
            }
            else if (this.file.TypeId == this.DocumentTypes.PARTDRAWING) {
                return 'mdi-file-cad';
            }
            else {
                return 'mdi-file-document';
            }
        }
    },

    computed: {

    },

    watch: {},
};
</script>


<style lang='scss' scoped>
.fileText {
    font-size: 12px;
    opacity: 0.9;
    overflow-wrap: break-word;
    inline-size: 100px;
    line-clamp: 3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-align: center;
}

.hoverlistitem:hover .hiddenBeforeHoverContent {
    display: block;
}

.hoverlistitem .hiddenBeforeHoverContent {
    display: none;
}
</style>