<template>
    <div>
        <h5 class="mb-2 titlecolor">{{ $t('partner.order.articles') }}</h5>
        <v-data-table :headers="articleTableHeaders" :items="orderLines" hide-default-footer disable-pagination
            item-key="Id">
            <template v-slot:item.ArticleNumber="{ item, /*index*/ }">
                <v-icon size="18" class="mr-2">
                    {{ getArticleTypeIcon(item.ArticleType) }}
                </v-icon>
                {{ item.ArticleNumber }}
            </template>

            <template v-slot:item.Description="{ item, /*index*/ }">
                <!-- 
                <v-text-field :id="'description_' + index" v-if="!readonly" v-model="item.ArticleDescription"
                    name="description" outlined dense single-line hide-details>
                </v-text-field>
                <template v-if="readonly">
                    {{ item.ArticleDescription }}
                </template>
-->
                {{ item.ArticleDescription }}
            </template>

            <template v-slot:item.Amount="{ item, index }">
                <v-text-field :id="'amount_' + index" v-if="!readonly && false" v-model="item.ArticleCount"
                    name="amount" outlined dense reverse single-line type="number" hide-details class="pt-0">
                </v-text-field>
                <template v-else>
                    {{ item.ArticleCount }}
                </template>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small @click="deleteOrderLine(item.Id)">
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>

        <v-progress-linear v-if="loading" indeterminate color="primary" height="1">
        </v-progress-linear>

        <OrderLineDialog v-if="!readonly" :machineJobId="machineJobId" :getArticleTypeIcon="getArticleTypeIcon"
            @added="getOrderLines()">
        </OrderLineDialog>
    </div>
</template>


<script>
import http from "@/api/client";
import OrderLineDialog from "@/components/Dialogs/OrderLineDialog.vue";

export default {
    name: 'OrderLines',
    props: {
        machineJobId: Number,
        readonly: Boolean,
    },
    components: {
        OrderLineDialog,
    },

    data() {
        return {
            loading: false,
            orderLinesFetching: false,
            orderLines: [],
        };
    },

    mounted() {
        this.getOrderLines();
    },

    methods: {
        getArticleTypeIcon(articleType) {
            var map = {
                GenericArticle: 'mdi-tag',
                DrivingArticle: 'mdi-car-clock',
                DrivingCostParkingArticle: 'mdi-parking',
                DrivingCostRoadTaxArticle: 'mdi-road-variant',
                DrivingCostZoneArticle: 'mdi-map-marker-radius',
                DrivingDistanceArticle: 'mdi-map-marker-distance',
                MiscPartCostArticle: 'mdi-help-box',
                DefaultWorkArticle: 'mdi-clock-time-four',
            };
            return map[articleType] ?? 'mdi-tag';
        },

        getOrderLines() {
            this.orderLinesFetching = true;
            return http()
                .get(`api2/partner/OrderDetail/List/${this.machineJobId}`)
                .then((response) => {
                    this.orderLines = response.data;
                    this.orderLinesFetching = false;
                    this.loading = false;
                });
        },

        updateOrderLine(orderLineToUpdate) {
            return http()
                .patch(`api2/partner/OrderDetail/${this.machineJobId}`, orderLineToUpdate)
                .then(() => {
                    this.loading = false;
                });
        },

        deleteOrderLine(orderlineId) {
            return http()
                .delete(`api2/partner/OrderDetail/${this.machineJobId}/${orderlineId}`)
                .then(() => {
                    this.getOrderLines();
                });
        },
    },

    computed: {
        articleTableHeaders() {
            var headers = [
                { text: this.$t('partner.order.article_number'), value: 'ArticleNumber', sortable: false, align: 'start', width: 150 },
                { text: this.$t('partner.order.description'), value: 'Description', sortable: false, align: 'start', width: 320 },
                { text: this.$t('partner.order.count'), value: 'Amount', sortable: false, align: 'start', width: 90 },
            ];
            if (!this.readonly) {
                headers.push({ text: '', value: 'actions', sortable: false, align: 'end', width: 10 });
            }
            return headers;
        },
    },

    watch: {},
};
</script>


<style lang='scss' scoped></style>