<template>
    <v-card v-if="comment" v-show="show" class="pa-3 rounded-0" color="rgb(255 246 189)" style="font-size: 12.5px;">
        <v-row no-gutters justify="space-between">
            <b>{{ $t('partner.job.first_line_comment') }}</b>
            <v-btn v-if="closable" small icon class="mt-n2 mr-n2" @click="show = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-row>
        {{ comment }} <br>
    </v-card>
</template>


<script>
export default {
    name: 'FirstLineCommentNote',
    props: {
        comment: String,
        closable: Boolean,
    },
    components: {},

    data() {
        return {
            show: true,
        };
    },

    methods: {},

    computed: {},

    watch: {},
};
</script>


<style lang='scss' scoped></style>