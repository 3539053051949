<template>
  <v-text-field v-model="keyword" class="search" :class="dense ? 'dense' : ''" :dense="dense" single-line hide-details
    :prepend-inner-icon="'mdi-magnify'" filled background-color="#f8f8f8" outlined clearable
    :label="placeholder || $t('text.search_in_list')" type="text" :loading="loading">
  </v-text-field>
</template>


<script>
import debounce from "debounce";

export default {
  name: "FilterSearch",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: String,
    dense: {
      type: Boolean,
      default: true,
    },
    placeholder: String,
    loading: Boolean,
  },
  components: {},

  data() {
    return {
    };
  },

  mounted() { },

  methods: {
    debounceValue: debounce(function (value) {
      this.$emit("input", value);
    }, 700),
  },

  computed: {
    keyword: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.debounceValue(newValue);
      }
    }
  },

  watch: {},
};
</script>


<style lang="scss">
.v-text-field.search.dense .v-input__control .v-input__slot {
  min-height: 0 !important;
  padding: 0 8px !important;
  margin-bottom: 2px !important;
  display: flex !important;
  align-items: center !important;
  font-size: 14px !important;
  border-radius: 6px;
}

.v-text-field.search.dense .v-input__control .v-input__slot .v-input__prepend-inner {
  margin-top: 4px !important;
  margin-right: 4px !important;
}

.v-text-field.search.dense .v-input__control .v-input__slot .v-input__append-inner {
  margin-top: 4px !important;
}

.v-text-field.search.dense .v-input__control .v-input__slot label {
  margin-top: -4px !important;
  font-size: 14px !important;
}

.v-text-field.search.dense .v-input__control .v-input__slot label.v-label--active {
  margin: 0 0 0 5px !important;
}

.v-text-field__details {
  margin: 2px !important;
}
</style>