<template>
    <div class="d-flex flex-column">
        <h6 class="titlecolor" v-text="label" />
        <p class="mb-3 mt-1" :class="value ? undefined : 'disabled--text'">
            {{ value || $t('partner.na') }}
        </p>
    </div>
</template>


<script>
export default {
    name: 'SimpleField',
    props: {
        label: String,
        value: String,
    },
    components: {},

    data() {
        return {};
    },

    methods: {},

    computed: {},

    watch: {},
};
</script>


<style lang='scss' scoped></style>