<template>
  <v-dialog v-model="showDialog" width="500" :fullscreen="isPhoneBool">
    <v-card>
      <v-container class="d-flex flex-column" :style="isPhoneBool ? 'max-height: 100vh;' : 'max-height: 75vh;'">
        <v-row style="flex-grow: 0; background-color: rgba(250, 250, 250, 0.5);">
          <v-col>
            <MachineListItem :item="machine">
            </MachineListItem>
          </v-col>
        </v-row>

        <v-row v-if="machine" style="flex-grow: 1; overflow-y: auto;">
          <v-col>
            <v-row no-gutters align="center" class="mt-0 mb-2">
              <v-col cols="auto" class="pr-3">
                <h5 style="opacity: 0.85;">{{ $t('partner.machine.product_info') }}</h5>
              </v-col>
              <v-col cols="0">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row no-gutters align="center">
              <v-col :cols="isPhoneBool ? 12 : 'auto'" class="pr-5">
                <MachineModelImage :size="150"
                  :relativePath="machine && (machine.ModelImage || machine.MachineModel && machine.MachineModel.ModelImage)">
                </MachineModelImage>
              </v-col>
              <v-col cols="0">
                <ImitationInput :label="$t('partner.machine.model')" :text="machine.MachineModel.Name">
                </ImitationInput>
                <ImitationInput :label="$t('partner.machine.machine_type')"
                  :text="machine.MachineModel.MachineType.Name">
                </ImitationInput>
                <ImitationInput :label="$t('partner.machine.supplier')" :text="machine.MachineModel.Supplier.Name">
                </ImitationInput>
              </v-col>
            </v-row>

            <v-row no-gutters align="center" class="mt-5 mb-2">
              <v-col cols="auto" class="pr-3">
                <h5 style="opacity: 0.85;">{{ $t('partner.machine.identifiers') }}</h5>
              </v-col>
              <v-col cols="0">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <ImitationInput :label="$t('partner.machine.name')" :text="machine.Name">
            </ImitationInput>
            <ImitationInput :label="$t('partner.machine.facility')" :text="machine.Facility">
            </ImitationInput>
            <ImitationInput :label="$t('partner.machine.serialnumber')" :text="machine.SerialNumber">
            </ImitationInput>
            <ImitationInput :label="$t('partner.machine.Qrcode')" :text="machine.Qrcode">
            </ImitationInput>

            <v-row no-gutters align="center" class="mt-5 mb-2">
              <v-col cols="auto" class="pr-3">
                <h5 style="opacity: 0.85;">{{ $t('partner.machine.dates') }}</h5>
              </v-col>
              <v-col cols="0">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <ImitationInput :label="$t('partner.machine.bought_date')"
              :text="boughtDate || $t('partner.machine.date_not_set')">
            </ImitationInput>
            <ImitationInput :label="$t('partner.machine.installation_date')"
              :text="installationDate || $t('partner.machine.date_not_set')">
            </ImitationInput>
            <ImitationInput :label="$t('partner.machine.warranty_expires_date')"
              :text="warrantyExpiresDate || $t('partner.machine.date_not_set')">
            </ImitationInput>
            <ImitationInput :label="$t('partner.machine.retired_date')"
              :text="retiredDate || $t('partner.machine.date_not_set')">
            </ImitationInput>
          </v-col>
        </v-row>

        <v-row style="flex-grow: 0; background-color: rgba(250, 250, 250, 0.85);">
          <v-col>
            <v-btn outlined @click="showDialog = false">{{ $t('partner.close') }}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>


<script>
import ImitationInput from "@/components/Inputs/ImitationInput.vue";
import MachineListItem from "@/components/ListItems/MachineListItem.vue";
import MachineModelImage from "@/components/Cards/MachineModelImage.vue";

export default {
  name: "MachineInfoDialog",
  props: {
    machine: Object,
    opentrigger: Number,
  },
  components: {
    ImitationInput,
    MachineListItem,
    MachineModelImage,
  },

  data() {
    return {
      showDialog: false,
      loading: false,
    };
  },

  mounted() { },

  methods: {
    formatDate(date) {
      return date ? new Date(date).toLocaleDateString() : null;
    },
  },

  computed: {
    boughtDate() {
      return this.formatDate(this.machine?.BoughtDate);
    },
    installationDate() {
      return this.formatDate(this.machine?.InstallationDate);
    },
    warrantyExpiresDate() {
      return this.formatDate(this.machine?.WarrantyExpiresDate);
    },
    retiredDate() {
      return this.formatDate(this.machine?.RetiredDate);
    }
  },

  watch: {
    opentrigger: function () {
      console.log(this.machine);
      this.showDialog = true;
    },
  },
};
</script>