import Vue from "vue";
import VueRouter from "vue-router";
import NotFound from "@/views/NotFound.vue";
import PortalSelectionPage from "@/views/PortalSelectionPage.vue";

import DirectComponentRoutes from "./DirectComponentRoutes";
import PublicRoutes from "./PublicRoutes";
import CustomerRoutes from "./CustomerRoutes";
import PartnerRoutes from "./PartnerRoutes";
import ServiceProviderRoutes from "./ServiceProviderRoutes";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: PortalSelectionPage,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "@/views/About.vue"),
  },
  PublicRoutes,
  DirectComponentRoutes,
  CustomerRoutes,
  ...PartnerRoutes,
  ServiceProviderRoutes,
  { path: "*", component: NotFound },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
