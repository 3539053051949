<template>
    <div :class="isPhoneBool ? 'pa-5' : 'pa-10'" style="background-color: white;"
        :style="'max-width: ' + (isPadBool ? (isPhoneBool ? 'calc(100vw - 24px)' : '100vw') : 'calc(100vw - 450px)') + '; min-width: ' + (isPadBool ? '90vw' : '50vw')">
        <v-row no-gutters>
            <v-col>
                <h3>{{ $t('partner.job.order') }} {{ job?.Order?.Id }}</h3>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>

        <v-divider class="mt-4"></v-divider>

        <v-row no-gutters class="pt-5" justify="space-between" id="customer">
            <v-col cols="auto" style="min-width: 150px; max-width: 300px;" :class="isPhoneBool ? 'pb-5' : ''">
                <b>{{ Customer.Id }} {{ Customer.Name }}</b><br>
                <v-container v-if="Address" fluid class="pa-0">
                    <v-row no-gutters>
                        <v-col>
                            {{ Address.street }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            {{ Address.zip }} {{ Address.city }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            {{ Address.country }}
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <v-btn text small color="primary" class="px-0" style="text-transform: none;"
                                :href="Address?.mapslink" :target="'_blank'">
                                <v-icon size="13">mdi-map-marker</v-icon>
                                {{ $t('partner.job.show_on_map') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
                <div v-else class="disabled--text">
                    {{ $t('partner.job.no_delivery_address') }}
                </div>
            </v-col>
            <v-col v-if="CustomerPerson" cols="auto" style="min-width: 150px; max-width: 230px;">
                <v-row no-gutters align="center">
                    <v-col cols="0">
                        {{ CustomerPerson.Name || "n/a" }}
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" :class="CustomerPerson.Mobile ? undefined : 'disabled--text'">
                    <v-col cols="auto">
                        <v-icon size="13" class="pr-1">mdi-phone</v-icon>
                    </v-col>
                    <v-col cols="0">
                        {{ CustomerPerson.Mobile || "n/a" }}
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" :class="CustomerPerson.Email ? undefined : 'disabled--text'">
                    <v-col cols="auto">
                        <v-icon size="13" class="pr-1">mdi-email</v-icon>
                    </v-col>
                    <v-col cols="0">
                        {{ CustomerPerson.Email || "n/a" }}
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-divider class="my-5"></v-divider>

        <div>
            <h4 v-if="isBatchJob" class="mb-5 titlecolor">{{ $t('partner.machine.machines') }}</h4>

            <div v-for="(machineJob, index) in InverseParent" :key="index" :id="machineJob.Machine.Id" class="pt-3">
                <div :style="isBatchJob ? 'border-left: 3px solid #e6e6e6;' : ''" :class="isBatchJob ? 'pl-4' : ''">
                    <MachineListItem :item="machineJob.Machine" @click="openMachineInfoDialog(machineJob.Machine)">
                        <template v-slot:actions>
                            <v-row no-gutters>
                                <!-- 
                                <v-btn icon>
                                    <v-icon color="green">mdi-file-delimited</v-icon>
                                </v-btn>
                                <v-btn icon>
                                    <v-icon color="green">mdi-file-clock</v-icon>
                                </v-btn>
                                <v-btn icon>
                                    <v-icon>mdi-folder-open</v-icon>
                                </v-btn>
                                -->
                                <v-btn icon>
                                    <v-icon>mdi-information</v-icon>
                                </v-btn>
                            </v-row>
                        </template>
                    </MachineListItem>
                    <MachineInfoDialog :opentrigger="machineDialogTrigger" :machine="machineDialogItem">
                    </MachineInfoDialog>

                    <h5 class="mb-2 pt-4 titlecolor" :id="'comments-' + machineJob.Machine.Id">{{
                        $t('partner.job.comments') }}</h5>
                    <h6 class="titlecolor">{{ $t('partner.job.customer_comment') }}</h6>
                    <p class="mb-3 mt-1" :class="machineJob.CustomerComment ? undefined : 'disabled--text'">
                        {{ machineJob.CustomerComment || "n/a" }}
                    </p>
                    <h6 class="titlecolor">{{ $t('partner.job.technician_comment') }}</h6>
                    <p v-if="isFinalized" class="mb-3 mt-1"
                        :class="machineJob.TechnicianComment ? undefined : 'disabled--text'">
                        {{ machineJob.TechnicianComment || "n/a" }}
                    </p>
                    <v-textarea v-else v-model="machineJob.editTechnicianCommentValue" auto-grow rows="1"
                        hide-details="auto" persistent-placeholder class="mb-3 pt-0 mt-0" outlined dense
                        :loading="machineJob.commentLoading" :disabled="machineJob.commentLoading"
                        :error="machineJob.errors && machineJob.errors.length > 0" :error-messages="machineJob.errors"
                        clearable @blur="setComment(machineJob)">
                    </v-textarea>
                    <h6 class="titlecolor">{{ $t('partner.job.invoice_comment') }}</h6>
                    <p class="mb-5 mt-1" :class="machineJob.InvoiceComment ? undefined : 'disabled--text'">
                        {{ machineJob.InvoiceComment || "n/a" }}
                    </p>

                    <MachineCounters :machineid="machineJob.Machine.Id" :readonly="isFinalized" class="py-7"
                        :id="'counters-' + machineJob.Machine.Id">
                    </MachineCounters>

                    <OrderLines :machineJobId="machineJob.Id" :readonly="isFinalized" class="pt-7 mb-7"
                        :id="'articles-' + machineJob.Machine.Id">
                    </OrderLines>
                </div>

                <v-divider v-if="(index + 1) < (InverseParent.length)" class="my-7"
                    style="border-width: 1px; border-color: transparent; opacity: 0.5;">
                    <!-- var(--v-primary-base) -->
                </v-divider>
            </div>
        </div>
    </div>
</template>


<script>
import Vue from "vue";
import http from "@/api/client";
import MachineCounters from "@/components/MachineCounters.vue";
import MachineListItem from "@/components/ListItems/MachineListItem.vue";
import OrderLines from "@/components/OrderLines.vue";
import MachineInfoDialog from "@/components/Dialogs/MachineInfoDialog.vue";


export default {
    name: "JobReportImitation",
    props: {
        jobLoading: Boolean,
        job: Object,
    },
    components: {
        MachineCounters,
        MachineListItem,
        OrderLines,
        MachineInfoDialog,
    },

    data() {
        return {
            machineDialogTrigger: 1,
            machineDialogItem: null,
        };
    },

    mounted() { },

    methods: {
        async setComment(machineJob) {
            if (machineJob.editTechnicianCommentValue != machineJob.TechnicianComment) {
                machineJob.TechnicianComment = machineJob.editTechnicianCommentValue;
                machineJob.errors = null;
                Vue.set(machineJob, 'commentLoading', true);
                await http()
                    .patch(`api2/partner/jobs/${machineJob.Id}/setTechnicianComment`, { TechnicianComment: machineJob.TechnicianComment })
                    .catch((error) => {
                        console.error(error);
                        machineJob.errors = 'Error saving value.';
                    })
                    .finally(() => {
                        Vue.set(machineJob, 'commentLoading', false);
                    });
            }
        },

        openMachineInfoDialog(machine) {
            this.machineDialogItem = machine;
            this.machineDialogTrigger++;
        },
    },

    computed: {
        Customer() {
            return this.job?.Customer;
        },
        Address() {
            if (this.job.CustomerDeliveryAdressStreet ||
                this.job.CustomerDeliveryAdressZip ||
                this.job.CustomerDeliveryAdressCity ||
                this.job.CustomerDeliveryAdressCountry) {
                var address = {
                    street: this.job.CustomerDeliveryAdressStreet,
                    zip: this.job.CustomerDeliveryAdressZip,
                    city: this.job.CustomerDeliveryAdressCity,
                    country: this.job.CustomerDeliveryAdressCountry
                }
                address.mapslink = `http://maps.google.com/?q=${address.street}, ${address.zip} ${address.city}, ${address.country}`;
                return address;
            }
            else {
                return null;
            }
        },
        CustomerPerson() {
            return this.job?.CustomerPerson
        },
        InverseParent() {
            return this.job?.InverseParent;
        },

        isBatchJob() {
            return this.job?.InverseParent.length > 1;
        },
        isFinalized() {
            return this.job?.JobStatus.Id >= 50;
        },
    },

    watch: {},
};
</script>


<style>
.titlecolor {
    opacity: 0.8;
}

.v-textarea {
    font-size: 0.9em !important;
}

/* hides datatable empty text */
.v-data-table__empty-wrapper {
    display: none !important;
}
</style>