<template>
    <v-container v-if="message">
        <v-card class="text-center">
            <v-card-text>
                {{ message }}
            </v-card-text>
            <v-card-text>
                Click <router-link :to="{ name: 'PartnerJobList' }">here</router-link> to go to the partner portal
            </v-card-text>
        </v-card>
    </v-container>
    <v-container fluid v-else>
        <v-card class="text-center">
            <v-card-text>
                {{ $t('partner.working') }}
            </v-card-text>
            <v-progress-linear indeterminate></v-progress-linear>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            message: ''
        }
    },
    mounted() {
        if (this.$route.name == 'PartnerCode') {
            console.log("Route", this.$route.params.code);
            const code = this.$route.params.code;
            this.$store.dispatch("partnerCodeLogin", code)
                .then((results) => {
                    if (results.success) {
                        this.$router.push({ name: 'PartnerCodeJob', params: { code } })
                    }
                    else {
                        this.message = results.error?.response?.data?.Message
                    }
                })
                .catch((e => {
                    console.log("Unknown error: ", e)
                }));
        }
    },
};
</script>