<template>
    <v-card class="my-4">
        <v-list-item dense>
            <v-list-item-icon class="my-auto mr-3">
                <v-icon size="20">mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>
                    {{ startTime }} - {{ endTime }}, {{ date }}
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{ participantsText }}
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-card>
</template>


<script>

export default {
    name: 'AppointmentCard',
    props: {
        appointment: Object,
    },
    components: {},

    data() {
        return {};
    },

    methods: {},

    computed: {
        startTime() {
            return new Date(this.appointment.StartTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        },
        endTime() {
            return new Date(this.appointment.EndTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        },
        date() {
            return new Date(this.appointment.StartTime).toLocaleDateString();
        },

        participantsText() {
            return this.appointment?.Participants?.map((item) => item.User.Name).join(", ");
        },
    },

    watch: {},
};
</script>


<style lang='scss' scoped></style>